body {
  margin: 0;
  font-family: "Cabin Condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* TODO Change to theme */
  background-color: #24272c !important;
  height: 100vh;
}

@media screen and (max-height: 850px) {
  body {
    height: 100%;
    overflow-y: hidden;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MuiTooltip-tooltip {
  margin-top: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.bonding-curve-tooltip {
  z-index: 1000 !important;
}

* {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

*::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
